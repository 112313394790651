import * as React from 'react';
import { graphql, Link, navigate } from 'gatsby';

import defaultFeature from '../images/default_blog_feature_img.jpg';
import Typography from '../components/Typography/Typography';
import * as classes from './blogs.module.scss';
import Layout from '../components/Layout/Layout';

const BlogList = ({ data }) => {
  if (!data) return null;

  console.log(data.allPrismicPosts.nodes, 'blogList');
  return (
    <Layout>
      <div className={classes.root}>
        <Typography variant="h1" align="center">
          Our Blog
        </Typography>
        <div className={classes.blogsList}>
          {data.allPrismicPosts.nodes.map((item) => {
            const date = new Date(item.last_publication_date);

            return (
              <button
                onClick={() => navigate(`/blogs/${item.uid}`)}
                key={item.data.title.text}
                className={classes.blogCard}
              >
                <img
                  src={item.data.feature_img?.url ?? defaultFeature}
                  alt={item.data.feature_img?.alt ?? 'Feature image'}
                />
                <div className={classes.blogMainInfo}>
                  <Typography variant="h3">{item.data.title.text}</Typography>
                  <Typography variant="span">
                    {date.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                  <Typography variant="p">
                    {item.data.blog_content.text.slice(0, 100)}...
                  </Typography>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicPosts {
      nodes {
        last_publication_date
        uid
        data {
          feature_img {
            alt
            url
          }
          title {
            text
          }
          blog_content {
            text
          }
        }
      }
    }
  }
`;

export default BlogList;
